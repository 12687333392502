import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";
import * as Config from "./serverConfig";
// import { AddressFromLatLng } from "./AddressFromLatLng";
// import { OpenReserveGeocoding } from "./OpenReverseGeocoding";
import { Nominatim } from "./NominatimReserveGeocoding";

export const getTrucks = createAsyncThunk("trucks/getTrucks", async () => {
	const link = Config.Server?.link + "mode=getTrucksStat";
	let dataList = [];

	const response = await axios.get(link);
	const json = await response.data;

	if (json.success) {
		const promises = json.data.map(async (item) => {
			//** Too frenquently call Google API cause high cost */
			// await AddressFromLatLng({ lat: item.lat, lng: item.lng }).then(
			//   (result) => {
			//     item.address = result.address;
			//     item.region = result.region;
			//   }
			// );

			// await OpenReserveGeocoding({ lat: item.lat, lng: item.lng }).then(
			//   (result) => {
			//     item.address = result.address;
			//     item.region = result.region;
			//   }
			// ).catch((error) => {
			//   item.address = "No Data";
			//   item.region = "No Data";
			//   console.log("Error: ", error);
			// });
			// console.log(item);

			await Nominatim({ lat: item.lat, lng: item.lng })
				.then((result) => {
					item.address = result.address;
					item.region = result.region;
					// console.log(result.region);
				})
				.catch((error) => {
					item.address = "No Data";
					item.region = "No Data";
					console.log("Error: ", error);
				});
			// console.log(item);
			return new Models.TruckModel(item);
		});
		dataList = await Promise.all(promises);
		return dataList;
	} else {
		return {};
	}

	// return data;
});

const trucksAdapter = createEntityAdapter({});

export const { selectAll: selectTrucks, selectById: selectTruckById } =
	trucksAdapter.getSelectors((state) => state.trucks);

const Slice = createSlice({
	name: "trucks",
	initialState: trucksAdapter.getInitialState({
		searchText: "",
	}),
	reducers: {
		setTrucksSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[getTrucks.fulfilled]: trucksAdapter.setAll,
		// [removeMerchants.fulfilled]: (state, action) =>
		//   trucksAdapter.removeMany(state, action.payload),
	},
});

export const { setTrucksSearchText } = Slice.actions;

export const selectTrucksSearchText = ({ trucks }) => trucks.searchText;

export default Slice.reducer;
