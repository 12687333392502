export default class TruckStatsModel {
	constructor(item) {
    this.id = item?.id;
		this.date = item?.date;
		this.ron95 = item?.ron95;
		this.ron97 = item?.ron97;
		this.diesel = item?.diesel;
		this.series_type = item?.series_type;
		this.diesel_eastmsia = item?.diesel_eastmsia;
	}
}
