import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as Config from "./serverConfig";

export const setMonitorLog = createAsyncThunk(
  "system/setMonitorLog",
  async (monitorLog, { dispatch, getState }) => {
    // console.log("monitorLog", monitorLog);
    return monitorLog;
  }
);

export const getMonitorLog = createAsyncThunk(
  "system/getMonitorLog",
  async (props) => {
    const link = Config.Server?.link + "mode=get-monitoringLog";
    let dataList = [];

    const response = await axios({
      method: "get",
      url: link,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    const json = await response.data;

    if (json.success) {
      // console.log("json", json);
      dataList = json.data?.map((item) => {
        return item;
      });
      return dataList;
    } else {
      return { success: json.success };
    }
  }
);

const monitorLogAdapter = createEntityAdapter({});

export const {
  selectAll: selectMonitorLog,
  selectById: selectMonitorLogById
} = monitorLogAdapter.getSelectors((state) => state.monitorLog);

const Slice = createSlice({
  name: "monitorLog",
  initialState: monitorLogAdapter.getInitialState({
    searchText: "",
  }),
  reducers: {
    setMonitorLogSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getMonitorLog.fulfilled]: (state, action) => {
      monitorLogAdapter.setAll(state, action.payload);
    },
    [setMonitorLog.fulfilled]: (state, action) => action.payload,
    // [removeMerchants.fulfilled]: (state, action) =>
    //   trucksAdapter.removeMany(state, action.payload),
  },
});

export const { setMonitorLogSearchText } = Slice.actions;

export const selectMonitorLogSearchText = ({ monitorLog }) => monitorLog.searchText;

export default Slice.reducer;
