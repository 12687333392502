import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";
import * as Config from "./serverConfig";
import { FuelPrice } from "./FuelPrice.js";

export const getTruckStats = createAsyncThunk("trucks/getTruckStats", async (props) => {
	const link = Config.Server?.link + "mode=getTruckStats";
	let dataList = [];

	const params = new URLSearchParams();
	params.append("id", props.id);
	params.append("from", props.from);
	params.append("to", props.to);

	const response = await axios({
		method: "post",
		url: link,
		data: params,
		headers: {
			"Content-Type": "application/x-www-form-urlencoded",
		},
	});
	// const data = await response.data;

	// return data === undefined ? null : data;
	const json = await response.data;
	const fuelPrice = await FuelPrice();

	// console.log(fuelPrice)

	if (json.success) {
		dataList = json.data.map((item) => {
			item.fuel_consumption_price =
				parseFloat(item?.total_oil_consumed_percent || 0) *
					parseFloat(item?.fuel_capacity || 0) *
					fuelPrice >
				0
					? (parseFloat(item?.total_oil_consumed_percent || 0) *
							parseFloat(item?.fuel_capacity || 0) *
							fuelPrice) /
						100
					: 0;
			item.fuel_addition_price =
				parseFloat(item?.total_oil_added_percent || 0) *
					parseFloat(item?.fuel_capacity || 0) *
					fuelPrice >
				0
					? (parseFloat(item?.total_oil_added_percent || 0) *
							parseFloat(item?.fuel_capacity || 0) *
							fuelPrice) /
						100
					: 0;
			return new Models.TruckStatsModel(item);
		});
		return dataList;
	} else {
		return { success: json.success };
	}
});

const truckStatsAdapter = createEntityAdapter({});

export const {
	selectAll: selectTruckStatsReport,
	selectById: selectTruckStatsReportById,
} = truckStatsAdapter.getSelectors((state) => state.truckStatsReport);

const Slice = createSlice({
	name: "truckStats",
	initialState: truckStatsAdapter.getInitialState({
		searchText: "",
	}),
	reducers: {
		setTruckStatsReportSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: {
		[getTruckStats.fulfilled]: truckStatsAdapter.setAll,
		// [removeMerchants.fulfilled]: (state, action) =>
		//   trucksAdapter.removeMany(state, action.payload),
	},
});

export const { setTruckStatsReportSearchText } = Slice.actions;

export const selectTruckStatsReportSearchText = ({ truckStatsReport }) =>
	truckStatsReport.searchText;

export default Slice.reducer;
