import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";
import * as Config from "./serverConfig";
import { AddressFromLatLng } from "./AddressFromLatLng";
import { OpenReserveGeocoding } from "./OpenReverseGeocoding";

export const getOverSpeedHistory = createAsyncThunk(
  "trucks/getOverSpeedHistory",
  async (props) => {
    const link = Config.Server?.link + "mode=getOverSpeedHistory";
    let dataList = [];

    const params = new URLSearchParams();
    params.append("id", props.id);
    params.append("from", props.from);
    params.append("to", props.to);

    const response = await axios({
      method: "post",
      url: link,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    // const data = await response.data;

    // return data === undefined ? null : data;
    const json = await response.data;

    // console.log(json);
    
    if (json.success) {
      dataList = json.data.map((item) => {
        if(item?.detail_no == "0") {
          item.detail_no = item?.detail.match(/\d+(?= km\/h)/)[0] || 0;
        }
        return new Models.OverSpeedHistoryModel(item);
      });
      return dataList;
    } else {
      return { success: json.success };
    }
  }
);

const overSpeedHistoryAdapter = createEntityAdapter({});

export const { selectAll: selectOverSpeedHistory, selectById: selectOverSpeedHistoryById } =
overSpeedHistoryAdapter.getSelectors((state) => state.overSpeedHistory);

const Slice = createSlice({
  name: "overSpeedHistory",
  initialState: overSpeedHistoryAdapter.getInitialState({
    searchText: "",
  }),
  reducers: {
    setOverSpeedHistorySearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getOverSpeedHistory.fulfilled]: overSpeedHistoryAdapter.setAll,
    // [removeMerchants.fulfilled]: (state, action) =>
    //   trucksAdapter.removeMany(state, action.payload),
  },
});

export const { setOverSpeedHistorySearchText } = Slice.actions;

export const selectOverSpeedHistorySearchText = ({ overSpeedHistory }) => overSpeedHistory.searchText;

export default Slice.reducer;
