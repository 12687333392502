import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";
import * as Config from "./serverConfig";
import { AddressFromLatLng } from "./AddressFromLatLng";
import { OpenReserveGeocoding } from "./OpenReverseGeocoding";

export const getTruckRouteHistory = createAsyncThunk(
  "trucks/getTruckRouteHistory",
  async (props) => {
    const link = Config.Server?.link + "mode=getTruckRouteHistory";
    let dataList = [];

    const params = new URLSearchParams();
    params.append("id", props.id);
    params.append("from", props.from);
    params.append("to", props.to);
    props.period_case && params.append("period_case", props.period_case); // this param is in mins

    const response = await axios({
      method: "post",
      url: link,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    // const data = await response.data;

    // return data === undefined ? null : data;
    const json = await response.data;

    if (json.success) {
      dataList = json.data.map((item) => {
        return new Models.TruckRouteModel(item);
      });
      return dataList;
    } else {
      return { success: json.success };
    }
  }
);

const Adapter = createEntityAdapter({});

export const { selectAll: selectTruckRoute, selectById: selectTruckRouteById } =
Adapter.getSelectors((state) => state.truckRoute);

const Slice = createSlice({
  name: "truckRoute",
  initialState: Adapter.getInitialState({
    searchText: "",
  }),
  reducers: {
    setTruckRouteSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || "" }),
    },
  },
  extraReducers: {
    [getTruckRouteHistory.fulfilled]: Adapter.setAll,
    // [removeMerchants.fulfilled]: (state, action) =>
    //   trucksAdapter.removeMany(state, action.payload),
  },
});

export const { setTruckRouteSearchText } = Slice.actions;

export const selectTruckRouteSearchText = ({ truckRoute }) => truckRoute.searchText;

export default Slice.reducer;
