export default class TruckStatsModel {
  constructor(item) {
    this.id = item?.id;
    this.level = item?.level;
    this.truck_id = item?.truckID;
    this.short_message = item?.short_message;
    this.detail = item?.detail;
    this.detail_no = item?.detail_no;
    this.date = item?.created;
    this.source = item?.source;
    this.vehiclename = item?.vehiclename;
    this.Drivername = item?.Drivername;
    this.PlateNumber = item?.PlateNumber;
    this.branch = item?.branch;
    this.detail_no_alt = item?.detail.match(/\d+(?= km\/h)/)[0] || 0;
  }
}
