// export default App;
import React, { useEffect, useState, useCallback, Fragment } from "react";
import _ from "lodash";
import "./App.css";
import {
	MAP_REFRESH_RATE,
	DATA_REFRESH_RATE,
	DEFAULT_IDLE_LIMIT_TIME,
	DEFAULT_SNACKBAR_HIDE_DURATION,
	COLORS,
	DEFAULT_CRITICAL_FUEL_CONSUMPTION_PER_MIN,
} from "@constants";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useWarning } from "./provider/WarningContext";
import { directServer, sendNotification } from "@utils";
import { DialogModal } from "@components";
// import { Provider } from 'react-redux';
import doremi from "@assets/sounds/doremi.mp3";
import dot from "@assets/sounds/dot.mp3";
import realistic_dot from "@assets/sounds/realistic_dot.mp3";
import sharp_tone from "@assets/sounds/sharp_tone.wav";
import xylophone from "@assets/sounds/xylophone.mp3";
import { selectUser } from "./@slices/userSlice";
import { useSelector } from "react-redux";
import { getSystemConfig, selectSystemConfig } from "./@slices/monitorLogSlice";
import { useDispatch } from "react-redux";

const soundConverter = (sound) => {
	switch (sound) {
		case "doremi":
			return doremi;
		case "dot":
			return dot;
		case "realistic_dot":
			return realistic_dot;
		case "sharp_tone":
			return sharp_tone;
		case "xylophone":
			return xylophone;
		default:
			return null;
	}
};

const snackbarVariant = ["default", "error", "warning", "info", "success"];

const AppNotification = () => {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	// useEffect(() => {
	//   dispatch(getSystemConfig());
	// }, [dispatch]);
	// const systemConfig = useSelector(selectSystemConfig);
	// console.log(systemConfig);
	// const {
	//   MAP_REFRESH_RATE,
	//   DATA_REFRESH_RATE,
	//   DEFAULT_IDLE_LIMIT_TIME,
	//   DEFAULT_SNACKBAR_HIDE_DURATION,
	// } = systemConfig[0];

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const {
		overSpeedWarning,
		overSpeedLimit,
		idleLimitWarning,
		overSpeedWarningSound,
		idleLimitWarningSound,
		mapsRefreshTimer,
		fuelTheftWarning,
		fuelTheftWarningSound,
	} = useWarning();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const timeLimit = DEFAULT_IDLE_LIMIT_TIME;
	const hideDuration = DEFAULT_SNACKBAR_HIDE_DURATION;

	const handleModalStats = () => {
		setIsDialogOpen(false);
	};

	//** Get idling stats */
	useEffect(() => {
		if (idleLimitWarning && !(user.role === "" || user.role.length === 0)) {
			directServer({
				mode: "get-truckIdle",
			}).then((res) => {
				// console.log(res);
				if (res.length > 0) {
					// const truckLabel = res
					// 	.map(
					// 		(item) =>
					// 			`${item?.platenumber || "Unknown Plate"} (${item?.vehiclename || "Unknown Vehicle"})`
					// 	)
					// 	.join(", ");

					// directServer({
					// 	mode: "sendMonitoringEmail",
					// 	data: {
					// 		truck: truckLabel,
					// 		type: res[0]?.type || "Unknown",
					// 		email: user?.data.user_email || "",
					// 	},
					// }).then(() => {});

					res.map((item) => {
						let itemType = item.type.toLowerCase();

						if (idleLimitWarningSound !== null) {
							triggerSound({
								sound: idleLimitWarningSound,
								message: item?.short_message,
							});
						}

						triggerSnack({
							message: item?.short_message,
							variant: snackbarVariant.includes(itemType) ? itemType : "info",
							details: item?.detail,
						});
					});
				}
			});
		}
	}, [idleLimitWarning, user]);

	useEffect(() => {
		if (idleLimitWarning && !(user.role === "" || user.role.length === 0)) {
			const idleInterval = setInterval(() => {
				directServer({
					mode: "get-truckIdle",
				}).then((res) => {
					if (res.length > 0) {
            // const truckLabel = res
						// 	.map(
						// 		(item) =>
						// 			`${item?.platenumber || "Unknown Plate"} (${item?.vehiclename || "Unknown Vehicle"})`
						// 	)
						// 	.join(", ");

						// directServer({
						// 	mode: "sendMonitoringEmail",
						// 	data: {
						// 		truck: truckLabel,
						// 		type: res[0]?.type || "Unknown",
						// 		email: user?.data.user_email || "",
						// 	},
						// }).then(() => {});

						res.map((item) => {
							let itemType = item.type.toLowerCase();
							if (idleLimitWarningSound !== null) {
								triggerSound({
									sound: idleLimitWarningSound,
									message: item?.short_message,
								});
							}

							triggerSnack({
								message: item?.short_message,
								variant: snackbarVariant.includes(itemType) ? itemType : "info",
								details: item?.detail,
							});
						});
					}
				});
			}, DATA_REFRESH_RATE);

			return () => clearInterval(idleInterval);
		}
	}, [idleLimitWarning, idleLimitWarningSound, user]);

	//** Get trucks stats */
	useEffect(() => {
		if (overSpeedWarning && !(user.role === "" || user.role.length === 0)) {
			directServer({
				mode: "get-monitorSpeed",
			}).then((res) => {
				if (res.length > 0) {
          // const truckLabel = res
					// 	.map(
					// 		(item) =>
					// 			`${item?.platenumber || "Unknown Plate"} (${item?.vehiclename || "Unknown Vehicle"})`
					// 	)
					// 	.join(", ");

					// directServer({
					// 	mode: "sendMonitoringEmail",
					// 	data: {
					// 		truck: truckLabel,
					// 		type: res[0]?.type || "Unknown",
					// 		email: user?.data.user_email || "",
					// 	},
					// }).then(() => {});
            
					res.map((item) => {
						let itemType = item.type.toLowerCase();
						if (overSpeedWarningSound !== null) {
							triggerSound({
								sound: overSpeedWarningSound,
								message: item?.short_message,
							});
						}

						triggerSnack({
							message: item?.short_message,
							variant: snackbarVariant.includes(itemType) ? itemType : "warning",
							details: item?.detail,
						});
					});
				}
			});
		}
	}, [overSpeedWarning, user]);

	useEffect(() => {
		// console.log(overSpeedWarning && !(user.role === "" || user.role.length === 0))
		if (overSpeedWarning && !(user.role === "" || user.role.length === 0)) {
			const speedInterval = setInterval(() => {
				directServer({
					mode: "get-monitorSpeed",
				}).then((res) => {
					if (res.length > 0) {
            // const truckLabel = res
						// 	.map(
						// 		(item) =>
						// 			`${item?.platenumber || "Unknown Plate"} (${item?.vehiclename || "Unknown Vehicle"})`
						// 	)
						// 	.join(", ");

						// directServer({
						// 	mode: "sendMonitoringEmail",
						// 	data: {
						// 		truck: truckLabel,
						// 		type: res[0]?.type || "Unknown",
						// 		email: user?.data.user_email || "",
						// 	},
						// }).then(() => {});
            
						res.map((item) => {
							let itemType = item.type.toLowerCase();
							if (overSpeedWarningSound !== null) {
								triggerSound({
									sound: overSpeedWarningSound,
									message: item?.short_message,
								});
							}

							triggerSnack({
								message: item?.short_message,
								variant: snackbarVariant.includes(itemType) ? itemType : "warning",
								details: item?.detail,
							});
						});
					}
				});
			}, DATA_REFRESH_RATE);

			return () => clearInterval(speedInterval);
		}
	}, [overSpeedWarning, overSpeedLimit, overSpeedWarningSound, user]);

	//** Get trucks fuel stats */
	useEffect(() => {
		if (fuelTheftWarning && !(user.role === "" || user.role.length === 0)) {
			directServer({
				mode: "get-monitorFuel",
			}).then((res) => {
				if (res.length > 0) {
          // const truckLabel = res
					// 	.map(
					// 		(item) =>
					// 			`${item?.platenumber || "Unknown Plate"} (${item?.vehiclename || "Unknown Vehicle"})`
					// 	)
					// 	.join(", ");

					// directServer({
					// 	mode: "sendMonitoringEmail",
					// 	data: {
					// 		truck: truckLabel,
					// 		type: res[0]?.type || "Unknown",
					// 		email: user?.data.user_email || "",
					// 	},
					// }).then(() => {});
            
					res.map((item) => {
						let itemType = item.type.toLowerCase();
						if (fuelTheftWarningSound !== null) {
							triggerSound({
								sound: fuelTheftWarningSound,
								message: item?.short_message,
							});
						}

						triggerSnack({
							message: item?.short_message,
							variant: snackbarVariant.includes(itemType) ? itemType : "error",
							details: item?.detail,
						});
					});
				}
			});
		}
	}, [user]);

	useEffect(() => {
		// console.log(overSpeedWarning && !(user.role === "" || user.role.length === 0))
		if (fuelTheftWarning && !(user.role === "" || user.role.length === 0)) {
			const speedInterval = setInterval(() => {
				directServer({
					mode: "get-monitorFuel",
				}).then((res) => {
					if (res.length > 0) {
            // const truckLabel = res
						// 	.map(
						// 		(item) =>
						// 			`${item?.platenumber || "Unknown Plate"} (${item?.vehiclename || "Unknown Vehicle"})`
						// 	)
						// 	.join(", ");

						// directServer({
						// 	mode: "sendMonitoringEmail",
						// 	data: {
						// 		truck: truckLabel,
						// 		type: res[0]?.type || "Unknown",
						// 		email: user?.data.user_email || "",
						// 	},
						// }).then(() => {});
            
						res.map((item) => {
							let itemType = item.type.toLowerCase();
							if (fuelTheftWarningSound !== null) {
								triggerSound({
									sound: fuelTheftWarningSound,
									message: item?.short_message,
								});
							}

							triggerSnack({
								message: item?.short_message,
								variant: snackbarVariant.includes(itemType) ? itemType : "error",
								details: item?.detail,
							});
						});
					}
				});
			}, DATA_REFRESH_RATE);

			return () => clearInterval(speedInterval);
		}
	}, [user]);

	const triggerSound = (props) => {
		// audioPlayer.current.play();
		// audioRef.current.play().catch((err) => {console.log(err)});
		const soundFile = props.sound ? soundConverter(props.sound) : null;
		const message = props.message ? props.message : "Notification";
		const body = props.body ? props.body : "Click to get alert";

		if (soundFile === null) return;
		const audio = new Audio(soundFile);
		audio.play().catch((err) => {
			setIsDialogOpen(true);
			sendNotification(message, {
				body: body,
			});
		});
	};

	const triggerSnack = useCallback(
		(props) => {
			enqueueSnackbar(props.message, {
				variant: props?.variant || "default",
				autoHideDuration: hideDuration,
				action: (key) => (
					<Fragment>
						<Button
							size="small"
							onClick={() => alert(`${props.details}`)}>
							Detail
						</Button>
						<Button
							size="small"
							onClick={() => closeSnackbar(key)}>
							Dismiss
						</Button>
					</Fragment>
				),
			});
		},
		[enqueueSnackbar, closeSnackbar]
	);
	// if (!isLoaded) {
	//   return <p>Loading Google Map...</p>;
	// }
	return (
		<React.Fragment>
			<DialogModal
				// title="Route History"
				message="Please interact with the page for notification sound."
				handleModalStats={handleModalStats}
				isDialogOpen={isDialogOpen}
				icon={{
					icon: "carbon:warning-alt-filled",
					color: COLORS.yellow,
				}}
			/>
		</React.Fragment>
	);
};

export default AppNotification;
