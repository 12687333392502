import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as Models from "./models";

export const setFuelPrice = createAsyncThunk(
	"system/setFuelPrice",
	async (fuelPrice, { dispatch, getState }) => {
		// console.log("monitorLog", monitorLog);
		return fuelPrice;
	}
);

export const getFuelPrice = createAsyncThunk(
	"system/getFuelPrice", async (props) => {
	const link =
		"https://api.data.gov.my/data-catalogue/?id=fuelprice&limit=3&sort=-date&range=diesel[0:999]";
	let dataList = [];

	const response = await axios({
		method: "get",
		url: link,
		// data: params,
		// headers: { "Content-Type": "application/x-www-form-urlencoded" },
	});
	// const data = await response.data;

	// return data === undefined ? null : data;
	const json = await response;

	if (json.status == 200) {
		dataList = json.data.map((item, index) => {
			item = { ...item, id: (index + 1).toString() };
			return new Models.FuelPriceModel(item);
		});
		return dataList;
	} else {
		return {};
	}
});

const fuelPriceAdapter = createEntityAdapter({});

export const { selectAll: selectFuelPrice, selectById: selectFuelPriceById } =
	fuelPriceAdapter.getSelectors((state) => state.fuelPrice);

const Slice = createSlice({
	name: "fuelPrice",
	initialState: fuelPriceAdapter.getInitialState({
		searchText: "",
		ids: [],
		entities: {},
	}),
	reducers: {
		setFuelPriceSearchText: {
			reducer: (state, action) => {
				state.searchText = action.payload;
			},
			prepare: (event) => ({ payload: event.target.value || "" }),
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getFuelPrice.fulfilled, (state, action) => {
			fuelPriceAdapter.setAll(state, action.payload || []);
		});
	},
});

export const { setFuelPriceSearchText } = Slice.actions;

export const selectFuelPriceSearchText = ({ fuelPrice }) => fuelPrice.searchText;

export default Slice.reducer;
