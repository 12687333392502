/**** Open Strees Maps reverse Geocoding */
import axios from "axios";

export const FuelPrice = async () => {
	const link = `https://api.data.gov.my/data-catalogue/?id=fuelprice&limit=1&sort=-date&range=diesel[0:999]`;
	var result = [];

	try {
		const response = await axios.get(link);
		const json = response.data;

		if (json) {
			const result = json[0].diesel;
			return result;
		} else {
			return 0;
		}
	} catch (err) {
		// console.log(err);
		return 0;
	}
};
